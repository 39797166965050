<template>
  <el-dialog title="导入" :visible.sync="showImport" :before-close="handleClose" width="550px">
    <el-link type="primary" :href="fileUrl" :download="fileName"
      >下载导入模板</el-link
    >
    <el-input class="upload" v-model="filePath">
      <el-button slot="append">
        上传文件
        <input type="file" ref="inputer" @change="getFile" />
      </el-button>
    </el-input>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="comfirmImport">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      formData: "",
      filePath: "",
    };
  },
  props:['url','fileUrl','fileName','showImport'],
  methods: {
    getFile() {
        console.log(this.url)
      let files = this.$refs.inputer.files[0];
      let formData = new FormData();
      formData.append("files", files);
      this.filePath = files.name;
      this.formData = formData;
    },
    comfirmImport() {
      if (!this.formData) return this.$message.warning("请先上传文件");
      this.$axios
        .uploadFile(this.url, this.formData)
        .then((res) => {
          if (res.code == 0) {
            this.$message.success("导入成功");
            this.$emit('importSuccess')
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    handleClose(){
        this.$parent.$parent.showImport = !1;
    },
  },
};
</script>

<style lang="less" scoped>
.upload {
  margin-top: 20px;
  .el-button {
    position: relative;
    input {
      opacity: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
    }
  }
}
</style>